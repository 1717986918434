<template>
  <div>
    <ModalAccountRegisterUpdate
      :show="showInfoRegisterModal"
      @register-submit="onRegisterSubmit"
      @close="closeRegModal"
    />

    <ModalAccountVerificationCode
      v-if="showVerifyEmailCode"
      attribute="email"
      @close="closeAccountVerificationModal"
    />
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';

import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

export default defineComponent({
  name: 'ModalAccountMain',
  data() {
    return {
      showVerifyEmailCode: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, [
      'showInfoRegisterModal',
      'openWalletAfterRegister',
      'infoRegisterModalEmailOnly',
    ]),
  },
  methods: {
    onRegisterSubmit() {
      this.showVerifyEmailCode = true;
    },
    closeAccountVerificationModal() {
      this.showVerifyEmailCode = false;
      if (this.infoRegisterModalEmailOnly) {
        this.showInfoRegisterModal = false;
      }
    },
    closeRegModal() {
      const uiStore = useUiStore();
      const authStore = useAuthStore();

      this.showInfoRegisterModal = false;
      if (this.openWalletAfterRegister) {
        setTimeout(() => {
          if (authStore.wallet) {
            uiStore.openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
          } else {
            uiStore.openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[1]);
          }
        }, 500);
      } else {
        this.openWalletAfterRegister = true;
      }
    },
  },
});
</script>
